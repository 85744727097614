import axios from "axios";
import { setAuthorizationHeader } from "../../_utils/auth/header";

let baseURL;

if (process.env.NODE_ENV !== "production") {
	baseURL = "http://localhost:3000";
} else {
	if (process.env.REACT_APP_API_HOST) {
		baseURL = process.env.REACT_APP_API_HOST;
	} else {
		baseURL = "https://app.sportcompass.net";
	}
}

axios.defaults.baseURL = baseURL + "/admin_api";

export const urls = {
	stations: "/stations",
	games: "/games",
	fixtures: "/fixtures",
	login: "/login",
	dictionary_entries: "/dictionary_entries",
	clients: "/clients",
	client_accounts: "/client_accounts",
	sports: "/sports",
	poster_options: "/poster_options",
	baseURL,
	uploadDocument: "/admin/upload_document",
};

export const apiRequestService = {
	getLoginAuthentication: function (adminLoginDetails, cb, err_cb) {
		function onSuccess(response) {
			setAuthorizationHeader();
			const token = response.data.jwt;
			localStorage.setItem("authentication", token);
			cb(token);
		}
		function onError(exception) {
			let error;
			let generic_error = 'Login error';
			if (exception.response) {
				if (exception.response.status >= 400 && exception.response.status < 500) {
					error = exception.response.data.error || 'Looks like your email or password is invalid';
				} else {
					let message = (exception.response.data && exception.response.data.message) || generic_error;
					error = `${message} (${exception.response.status})`;
				}
			} else {
				error = generic_error + ', request to server failed, please try again later';
			}
			err_cb(error);
		}
		axios.post(urls.login, { auth: adminLoginDetails }).then(onSuccess).catch(onError);
	},
	logout() {
		localStorage.clear();
	},
	getFixtures: async (fixturesDetails) => {
		let fixtureRequestObj = {};
		fixtureRequestObj["sports"] = fixturesDetails["sports"]
			? fixturesDetails["sports"]
			: delete fixturesDetails["sports"];
		fixtureRequestObj["tags"] = fixturesDetails["tags"]
			? fixturesDetails["tags"]
			: delete fixturesDetails["tags"];

		fixtureRequestObj["start_date"] =
			fixturesDetails["start_date"] === ""
				? delete fixturesDetails["start_date"]
				: fixturesDetails["start_date"];
		fixtureRequestObj["end_date"] =
			fixturesDetails["end_date"] === ""
				? delete fixturesDetails["end_date"]
				: fixturesDetails["end_date"];
		try {
			const response = await axios.get(urls.fixtures, {
				params: fixturesDetails,
			});
			return response.data.fixtures;
		} catch (error) {
			return error.response;
		}
	},
	createFixture: function (fixturesDetails, cb, err) {
		axios.post(urls.fixtures, fixturesDetails).then(cb).catch(err);
	},
	deleteFixtureById: async (id) => {
		try {
			const response = await axios.delete(`${urls.fixtures}/${id}`);
			return response.data;
		} catch (error) {
			return error.response;
		}
	},
	getFixturesById: async (id) => {
		try {
			const response = await axios.get(`${urls.fixtures}/${id}`);
			return response.data;
		} catch (error) {
			return error.response;
		}
	},
	editFixture: async (id, fixturesDetails) => {
		try {
			const response = await axios.put(
				`${urls.fixtures}/${id}`,
				fixturesDetails
			);
			return response.data;
		} catch (error) {
			return error.response;
		}
	},
	getStations: async () => {
		try {
			const response = await axios.get(urls.stations);
			return response.data;
		} catch (error) {
			return error.response;
		}
	},
	editStations: async (id, name) => {
		try {
			const response = await axios.put(`${urls.stations}/${id}`, {
				name: `${name}`,
			});
			return response.data;
		} catch (error) {
			return error.response;
		}
	},
	deleteStationsById: async (id) => {
		try {
			const response = await axios.delete(`${urls.stations}/${id}`);
			return response.data;
		} catch (error) {
			return error.response;
		}
	},
	createStations: async (name) => {
		try {
			const response = await axios.post(urls.stations, { name: `${name}` });
			return response.data.station;
		} catch (error) {
			return error.response;
		}
	},
	createGames: async (fixtureID, stationID) => {
		try {
			const response = await axios.post(urls.games, {
				fixture_id: fixtureID,
				station_id: stationID,
			});
			return response.data;
		} catch (error) {
			return error.response;
		}
	},
	deleteGames: async (fixtureID, stationID) => {
		try {
			return await axios.delete(urls.games, {
				data: { fixture_id: fixtureID, station_id: stationID },
			});
		} catch (error) {
			return error.response;
		}
	},
	getDictionaryEntries: async () => {
		try {
			const response = await axios.get(urls.dictionary_entries);
			return response.data.dictionary_entries;
		} catch (error) {
			return error.response;
		}
	},
	createDictionaryEntry: async (deDetails, apply) => {
		try {
			deDetails.apply_entry = apply;
			const response = await axios.post(urls.dictionary_entries, deDetails);
			return response.data.dictionary_entry;
		} catch (error) {
      error.response.data["message"].split(":")[3] ? alert(error.response.data["message"].split(":")[3]) : alert(error.response.data["message"]);
			return error.response;
		}
	},
	deleteDictionaryEntryById: async (id) => {
		try {
			const response = await axios.delete(`${urls.dictionary_entries}/${id}`);
			return response.data;
		} catch (error) {
			return error.response;
		}
	},
	getClients: async () => {
		try {
			const response = await axios.get(urls.clients);
			return response.data.clients;
		} catch (error) {
			return error.response;
		}
	},
	editClientById: async (id, clientDetails) => {
		try {
			const response = await axios.put(`${urls.clients}/${id}`, clientDetails);
			return response.data;
		} catch (error) {
			return error.response;
		}
	},
	createClientAccount: function (client_account, cb, err_cb) {
		function onSuccess(response) {
			cb(response.data.client_account);
		}
		function onError(exception) {
			let errors;
			let generic_error = 'Error saving client account';
			if (exception.response) {
				if (exception.response.status >= 400 && exception.response.status < 500) {
					errors = exception.response.data.errors;
				} else {
					let message = (exception.response.data && exception.response.data.message) || generic_error;
					errors = {base: [`${message} (${exception.response.status})`]};
				}
			} else {
				errors = {base: [generic_error + ', request to server failed, please try again later']};
			}
			err_cb(errors);
		}
		axios.post(urls.client_accounts, client_account).then(onSuccess).catch(onError);
	},
	getSports: async () => {
		try {
			const response = await axios.get(urls.sports);
			return response.data;
		} catch (error) {
			return error.response;
		}
	},

	uploadDocument: async (values) => {
		let formData = new FormData();
		formData.append("upload", values);
		try {
			const response = await axios({
				method: "post",
				url: urls.uploadDocument,
				data: formData,
				headers: {
					"Content-Type": `multipart/form-data`,
				},
			});
			alert("Success!");
			return response.data;
		} catch (error) {
			alert("Something went wrong!");
			return error.response;
		}
	},

	createPosterEntry: async (values) => {
		try {
			const formData = new FormData();
			formData.append("name", values.name);
			formData.append("bar_logo_type", values.bar_logo_type);
			formData.append("color1", values.color1);
			formData.append("color2", values.color2);
			formData.append("color3", values.color3);
			formData.append("bkg_image", values.bkg_image);
			formData.append("example", values.example);
			formData.append("logo_sc", values.logo_sc);
			const response = await axios.post(urls.poster_options, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			setAuthorizationHeader();
			return response.data;
		} catch (error) {
			alert("error");
			return error.response;
		}
	},

	getPosterEntries: async () => {
		try {
			const response = await axios.get(urls.poster_options);
			return response.data.poster_options;
		} catch (error) {
			return error.response;
		}
	},

	deletePosterEntryById: async (id) => {
		try {
			const response = await axios.delete(`${urls.poster_options}/${id}`);
			return response.data;
		} catch (error) {
			return error.response;
		}
	},
};
