export const authTypes = {
	LOGIN_AUTHENTICATION: "LOGIN_AUTHENTICATION",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_REQUEST: "LOGIN_REQUEST",
	LOGIN_ERROR: "LOGIN_ERROR",

	LOG_OUT: "LOGOUT",
};

export const fixturesTypes = {
	GET_FIXTURES: "GET_FIXTURES",
	GET_FIXTURE_BY_ID: "GET_FIXTURE_BY_ID",
	EDIT_FIXTURE: "EDIT_FIXTURE",
	DELETE_FIXTURE: "DELETE_FIXTURE",
	CREATE_FIXTURE: "CREATE_FIXTURE",
};

export const stationsTypes = {
	GET_STATIONS: "GET_STATIONS",
	EDIT_STATIONS: "EDIT_STATIONS",
	DELETE_STATIONS: "DELETE_STATIONS",
	CREATE_STATIONS: "CREATE_STATIONS",
};

export const alertType = {
	ALERT_ACTION: "ALERT_ACTION",
};

export const gamesTypes = {
	CREATE_GAMES: "CREATE_GAMES",
	DELETE_GAMES: "DELETE_GAMES",
};

export const dictionaryEntryTypes = {
	CREATE_ENTRY: "CREATE_ENTRY",
	GET_ENTRIES: "GET_ENTRIES",
	DELETE_ENTRY: "DELETE_ENTRY",
};

export const posterEntryTypes = {
	CREATE_POSTER: "CREATE_POSTER",
	GET_POSTER: "GET_POSTER",
	DELETE_POSTER: "DELETE_POSTER",
};

export const clientTypes = {
	GET_CLIENTS: "GET_CLIENTS",
	EDIT_CLIENTS: "EDIT_CLIENTS",
};

export const sportsTypes = {
	GET_SPORTS: "GET_SPORTS",
};

export const TermsOfServiceTypes = {
	UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
};
