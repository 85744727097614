import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Formik, ErrorMessage } from 'formik';
import { Button, Form, Col } from 'react-bootstrap';

import textInputField from '../inputs/field/Text';

import validationSchema from '../../_utils/validation';
import Alert from 'react-bootstrap/Alert';
import { apiRequestService } from "../../_services/api/requests";

const CreateClientAccountForm = props => {
    const [serverErrorMessage, setServerErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const fieldToDisplayName = {
        client_name: 'Company name',
        name: 'User name',
        email: 'Email',
        password: 'Password',
        password_confirmation: 'Password confirmation',
        role: 'Role',
        currency_id: 'Currency'
    };
    return (
        <div>
            { serverErrorMessage !== '' ? (<Alert variant="danger">{serverErrorMessage}</Alert>) : null }
            { successMessage !== '' ? (<Alert variant="success" dismissible onClose={() => setSuccessMessage('')}>{successMessage}</Alert>) : null }
            <Formik
                initialValues={{
                    client_name: '',
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    role: 'ADMIN',
                    currency_id: 1
                }}
                validationSchema={validationSchema.ClientAccountSchema}
                onSubmit={(values, actions) => {
                    //const editor = this;
                    function onError(backendErrors) {
                        //set errors / state
                        actions.setSubmitting(false);
                        let backendFieldErrors = {};
                        for (let key in backendErrors) {
                            if (key === 'base') {
                                setServerErrorMessage(backendErrors[key][0]);
                            } else {
                                backendFieldErrors[key] = fieldToDisplayName[key] + ' ' + backendErrors[key].join('; ') + '.';
                            }

                        }
                        actions.setErrors(backendFieldErrors);
                    }
                    function onSuccess(client_account) {
                        actions.setSubmitting(false);
                        setSuccessMessage("Created account for " + client_account.email);
                        actions.resetForm();
                    }
                    apiRequestService.createClientAccount(values, onSuccess, onError);
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      values,
                      touched,
                      submitForm,
                      errors
                  }) => (
                    <Form noValidate autoComplete="off" onSubmit={(event) => {
                        setSuccessMessage('');
                        setServerErrorMessage('');
                        handleSubmit(event);
                    }}>
                        <Form.Group controlId="validationFormikTitle">
                            {textInputField(
                                'client_name',
                                null,
                                handleBlur,
                                values,
                                handleChange,
                                touched,
                                errors,
                                "Company Name"
                            )}
                        </Form.Group>
                        <Form.Group controlId="formGroupName">
                            {textInputField(
                                'name',
                                null,
                                handleBlur,
                                values,
                                handleChange,
                                touched,
                                errors,
                                "User name"
                            )}
                        </Form.Group>
                        <Form.Group controlId="formGroupName">
                            {textInputField(
                                'email',
                                null,
                                handleBlur,
                                values,
                                handleChange,
                                touched,
                                errors,
                                'Email',
                                null,
                                'off'
                            )}
                        </Form.Group>
                        <Form.Group controlId="formGroupPassword">
                            {textInputField(
                                'password',
                                null,
                                handleBlur,
                                values,
                                handleChange,
                                touched,
                                errors,
                                'Password',
                                'password',
                                'new-password'
                            )}
                        </Form.Group>
                        <Form.Group controlId="formGroupPassword">
                            {textInputField(
                                'password_confirmation',
                                null,
                                handleBlur,
                                values,
                                handleChange,
                                touched,
                                errors,
                                'Password confirmation',
                                'password',
                                'new-password'
                            )}
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                as="select"
                                name="role"
                                type="role"
                                onChange={handleChange}
                            >
                                <option value="ADMIN">ADMIN</option>
                                <option value="VIEWONLY">VIEWONLY</option>
                            </Form.Control>
                            <ErrorMessage name="role" component="div" className="text-danger" />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Currency</Form.Label>
                            <Form.Control
                                as="select"
                                name="currency_id"
                                type="currency_id"
                                onChange={handleChange}
                            >
                                <option value="1">dkk</option>
                                <option value="2">sek</option>
                                <option value="5">Nzd</option>
                            </Form.Control>
                            <ErrorMessage name="currency_id" component="div" className="text-danger" />
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formCreateFixture">
                                <Button variant="info" type="submit" className="main-form-fixtures-button">
                                    Create Client Account
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default connect(null)(CreateClientAccountForm);
