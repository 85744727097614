import * as Yup from "yup";

const LoginSchema = Yup.object({
	email: Yup.string().required("Email is required"),
	password: Yup.string().required("Password is required"),
});

const DateFieldSchema = Yup.object({
	sports: Yup.string().required("Sport type is required"),
	start_date: Yup.date().required("Start date is required"),
	end_date: Yup.date()
		.required("End date is required")
		.when(
			"start_date",
			(start_date, schema) =>
				start_date &&
				schema.min(start_date, "End date should be after the start date")
		),
});

const FixtureSchema = Yup.object({
	title: Yup.string().required("Fixture title is required"),
	name: Yup.string().required("Fixture name is required"),
	country: Yup.string().required("Country is required"),
	sport: Yup.string().required("Sport type is required"),
	time: Yup.date().required("Fixture time is required"),
});

const ClientAccountSchema = Yup.object({
	client_name: Yup.string().required("Company name is required"),
	name: Yup.string().required("User name is required"),
	email: Yup.string().email().required("Email is required"),
	password: Yup.string()
		.required("Password is required")
		.matches(
			/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*^[A-Za-z\d]).{8,}$/,
			"Password must contain at least 8 characters, a digit, a lower case character, an upper case character and a symbol"
		),
	password_confirmation: Yup.string().required("Password confirmation is required").oneOf(
		[Yup.ref("password")],
		"Passwords must match"
	),
	currency_id: Yup.string().required("Currency is required"),
	role: Yup.string().required("Role is required"),
});

const StationsSchema = Yup.object({
	station: Yup.string().required("Channel name is required"),
});

const DictionaryEntrySchema = Yup.object({
	matched_word: Yup.string().required("Word is required"),
	replacement: Yup.string().required("Replacement type is required"),
	entry_type: Yup.string().required("Entry type is required"),
});

const PosterEntrySchema = Yup.object({
	name: Yup.string().required("Poster's name is required"),
	bar_logo_type: Yup.string()
		.oneOf(["white_logo", "black_logo"])
		.required("Selecting a logo is required"),
});

const validationSchema = {
	LoginSchema,
	DateFieldSchema,
	FixtureSchema,
	StationsSchema,
	DictionaryEntrySchema,
	ClientAccountSchema,
	PosterEntrySchema,
};
export default validationSchema;
