import { authTypes, alertType } from '../_constants';

function authenticationReducer(state, action) {
  switch (action.type) {
    case authTypes.LOGIN_AUTHENTICATION:
      return { ...state, authentication: action.payload };
    case authTypes.LOGIN_ERROR:
      return {
        ...state,
        error_message: action.payload || 'Oops! Looks like your email or password is invalid'
      };
    case authTypes.LOGIN_SUCCESS:
      return { ...state, username: action.login_details.email };
    case alertType.ALERT_ACTION:
      return { ...state, error_message: undefined, fixture_deleted: undefined };
    default:
      return state;
  }
}

export default authenticationReducer;
