import React from "react";
import Form from "react-bootstrap/Form";
import { ErrorMessage } from "formik";

const textInputField = (
	input,
	placeholder,
	handleBlur,
	values,
	handleChange,
	touched,
	errors,
	label,
	type,
	autocomplete
) => {
	let formProps = {}
	if (autocomplete)
		formProps.autoComplete = autocomplete;
	return (
		<div>
			{label && <label>{label}</label>}
			<Form.Control
				type={type == null ? input : type}
				name={input}
				placeholder={placeholder ? "Enter " + placeholder : ''}
				onBlur={handleBlur}
				value={values[input]}
				onChange={handleChange}
				{...formProps}
			/>
			<ErrorMessage name={input} component="div" className="text-danger" />
		</div>
	);
};
export default textInputField;
