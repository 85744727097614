import React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Redirect } from 'react-router-dom';

import { Formik } from 'formik';

import './Login.scss';
import textInputField from '../inputs/field/Text';
import validationSchema from '../../_utils/validation';

function LoginForm(props) {
  const renderRedirect = () => {
    if (localStorage.getItem('authentication')) {
      return <Redirect to="/dashboard" />;
    }
  };
  return (
    <div className="Admin-form-login">
      {renderRedirect()}
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={validationSchema.LoginSchema}
        onSubmit={values => {
          props.login(values);
          renderRedirect();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <h5 className="title"> Log into the admin panel</h5>
            <Form.Group controlId="validationFormikEmail">
              {textInputField(
                'email',
                "your email",
                handleBlur,
                values,
                handleChange,
                touched,
                errors
              )}
            </Form.Group>
            <Form.Group controlId="formGroupPassword">
              {textInputField(
                'password',
                "your password",
                handleBlur,
                values,
                handleChange,
                touched,
                errors
              )}
            </Form.Group>
            {props.error_message !== undefined && <p className="login-error">{props.error_message}</p>}
            <Button variant="flat" type="submit" block>
              Log in
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default LoginForm;
